<template>
  <div>
    <h1>Terms and Conditions</h1>
    <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Mollitia alias eum, fuga maiores vero, saepe consectetur officiis incidunt corrupti inventore reprehenderit est sapiente quo animi! Delectus minima eius libero odio.</p>
  </div>
</template>

<script>
  export default {
    name: 'Termsandconditions'
  }
</script>

<style lang="scss" scoped>

</style>
